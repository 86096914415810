import store from '@/store'

// eslint-disable-next-line import/prefer-default-export
export const showMyTeamsCard = player => {
  const me = store.getters['user/getMe']

  if (!me) {
    return false
  }

  return me && player.id === me.id
}

export const allowOrganizerModeAccess = () => {
  const me = store.getters['user/getMe']

  if (!me) return false

  return me?.organizers.length > 0
}

export const canEditPlayer = player => {
  if (!player) {
    return false
  }

  const me = store.getters['user/getMe']

  if (!me) {
    return false
  }

  return me.id === player.id
}
