<template>
  <div>
    <b-card
      class="text-center mb-0"
      title="Apply for organizer"
    >
      <b-card-text class="text-left">
        You have community and would like to organizer your first event with
        ease? We got you covered! Head over to our application form and answer
        few short questions to apply.
      </b-card-text>
      <b-button
        size="sm"
        variant="primary"
        @click="redirectOrganizerApply"
      >
        Apply for organizer
      </b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BButton, BCard, BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
  },
  directives: {
    Ripple,
  },
  setup() {
    const redirectOrganizerApply = () => {
      window.open(
        'https://about.360clash.com/organizer/',
        '_blank',
        'noreferrer',
      )
    }

    return {
      redirectOrganizerApply,
    }
  },
}
</script>
