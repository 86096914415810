<template>
  <b-card
    class="text-center"
    no-body
  >
    <!-- Team List -->
    <div class="p-1">
      <h4>Teams</h4>

      <p
        v-if="!teams || teams.length === 0"
        class="font-italic"
      >
        Player does not belong to any team.
      </p>

      <vue-perfect-scrollbar
        v-else
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="div"
        style="max-height: 200px"
      >
        <b-media
          v-for="(team, index) in teams"
          :key="index"
          class="d-flex align-items-center my-teams-list"
        >
          <template #aside>
            <b-avatar
              :alt="team.name"
              :src="team?.avatar_image"
              :title="team.name"
              blank-color="#ccc"
              rounded="circle"
              width="42"
            />
          </template>
          <div class="d-flex justify-content-between align-items-center">
            <h6 class="media-heading">
              {{ team.name }}
            </h6>

            <b-button
              size="sm"
              variant="primary"
              @click="redirectToTeamProfile(team.id)"
            >
              Profile
            </b-button>
          </div>
        </b-media>
      </vue-perfect-scrollbar>
    </div>
    <!--/ Team List -->
  </b-card>
</template>

<script>
import {
  BAvatar, BButton, BCard, BMedia,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    BButton,
    BMedia,
    BCard,
    BAvatar,
    VuePerfectScrollbar,
  },
  props: {
    teams: {
      type: Array,
      default: () => null,
    },
  },
  data() {
    return {
      loading: true,
      perfectScrollbarSettings: {
        maxScrollbarLength: 80,
        wheelPropagation: false,
      },
    }
  },
  created() {},
  methods: {
    redirectToTeamProfile(teamId) {
      this.$router.push({ name: 'team-profile', params: { id: teamId } })
    },
  },
}
</script>

<style lang="scss">
  .my-teams-list {
    padding: 0.75rem !important;
  }

  .scroll-area {
    position: relative;
    margin: auto;
    //height: 220px;
  }
</style>
