var render = function () {
  var _vm$player, _vm$player2, _vm$player2$name, _vm$player3, _vm$player4, _vm$player5, _vm$player6, _vm$player7, _vm$player8, _vm$player8$achieveme, _vm$player9, _vm$player9$achieveme, _vm$player10, _vm$player11, _vm$player12, _vm$player12$tourname, _vm$player13, _vm$player14, _vm$player15, _vm$player15$tourname, _vm$player16, _vm$player16$achievem, _vm$player17, _vm$player18, _vm$player19, _vm$player19$achievem, _vm$player20, _vm$player20$history, _vm$player21, _vm$player22, _vm$me, _vm$me2, _vm$player23;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isLoading ? _c('b-row', [_c('b-col', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1)], 1) : _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "9",
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', [_c('b-card', {
    staticClass: "p-2",
    attrs: {
      "no-body": ""
    }
  }, [_vm.isLoading ? _c('b-row', [_c('b-col', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1)], 1) : _vm._e(), _vm.player ? _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-lg-0 mb-1",
    attrs: {
      "md": "3",
      "lg": "4"
    }
  }, [_c('b-avatar', {
    attrs: {
      "src": (_vm$player = _vm.player) === null || _vm$player === void 0 ? void 0 : _vm$player.avatar_image,
      "text": (_vm$player2 = _vm.player) === null || _vm$player2 === void 0 ? void 0 : (_vm$player2$name = _vm$player2.name) === null || _vm$player2$name === void 0 ? void 0 : _vm$player2$name.substring(0, 2),
      "variant": "light-primary",
      "rounded": "",
      "size": "120px"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "9",
      "lg": "8"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex align-items-start"
  }, [_c('h4', {
    staticClass: "mb-0 mr-1"
  }, [_vm._v(" " + _vm._s((_vm$player3 = _vm.player) === null || _vm$player3 === void 0 ? void 0 : _vm$player3.name) + " ")]), _c('span', {
    staticClass: "ml-1"
  }, [_vm.canEdit ? _c('b-button', {
    staticClass: "mb-1 font-small-1",
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.handleEditClick
    }
  }, [_vm._v(" Edit ")]) : _vm._e()], 1)])], 1), _c('b-row', [_c('b-col', [_vm.canEdit ? _c('div', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s((_vm$player4 = _vm.player) === null || _vm$player4 === void 0 ? void 0 : _vm$player4.email) + " ")]) : _vm._e()])], 1), _c('b-row', [_c('b-col', {
    staticClass: "mb-1 mr-1"
  }, [_c('social-links-list', {
    attrs: {
      "links": (_vm$player5 = _vm.player) === null || _vm$player5 === void 0 ? void 0 : _vm$player5.links
    }
  })], 1)], 1)], 1)], 1), _c('b-row', {
    staticClass: "flex-nowrap mt-1"
  }, [_c('b-col', {
    attrs: {
      "md": "3",
      "lg": "4"
    }
  }, [_c('b-badge', {
    staticClass: "p-1 font-medium-2 w-100",
    staticStyle: {
      "max-width": "120px"
    },
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" " + _vm._s(((_vm$player6 = _vm.player) === null || _vm$player6 === void 0 ? void 0 : _vm$player6.balance) || 0) + " € ")])], 1), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('b-row', {
    staticClass: "flex-column"
  }, [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "3"
    }
  }, [_vm.canAddFunds ? _c('b-button', {
    staticClass: "mb-1 font-small-1",
    attrs: {
      "disabled": "disabled",
      "size": "sm",
      "variant": "primary"
    }
  }, [_vm._v(" Add funds ")]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "3"
    }
  }, [_vm.canWithdrawFunds ? _c('b-button', {
    staticClass: "mb-1 font-small-1",
    attrs: {
      "disabled": "disabled",
      "size": "sm",
      "variant": "primary"
    }
  }, [_vm._v(" Withdraw funds ")]) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1 mt-lg-0",
    attrs: {
      "lg": "6",
      "md": "12"
    }
  }, [_c('h6', [_vm._v("Latest achievements")]), _c('div', [!((_vm$player7 = _vm.player) !== null && _vm$player7 !== void 0 && _vm$player7.achievements) || ((_vm$player8 = _vm.player) === null || _vm$player8 === void 0 ? void 0 : (_vm$player8$achieveme = _vm$player8.achievements) === null || _vm$player8$achieveme === void 0 ? void 0 : _vm$player8$achieveme.length) === 0 ? _c('p', {
    staticClass: "font-italic"
  }, [_vm._v(" Get ready to fill up this space with trophies and triumphs! ")]) : _vm._e(), ((_vm$player9 = _vm.player) === null || _vm$player9 === void 0 ? void 0 : (_vm$player9$achieveme = _vm$player9.achievements) === null || _vm$player9$achieveme === void 0 ? void 0 : _vm$player9$achieveme.length) > 0 ? _c('ul', {
    staticClass: "profile-achievements d-flex m-0 p-0 align-items-center flex-wrap"
  }, _vm._l((_vm$player10 = _vm.player) === null || _vm$player10 === void 0 ? void 0 : _vm$player10.achievements, function (achievement, index) {
    return _c('li', {
      key: index
    }, [_vm._v(" " + _vm._s(achievement.name) + " ")]);
  }), 0) : _vm._e()])])], 1) : _vm._e()], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-card', {
    staticClass: "p-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-tabs', {
    attrs: {
      "pills": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "active": "",
      "title": "Tournaments"
    }
  }, [_c('vue-perfect-scrollbar', {
    staticClass: "scroll-area",
    staticStyle: {
      "max-height": "200px"
    }
  }, [(_vm$player11 = _vm.player) !== null && _vm$player11 !== void 0 && _vm$player11.tournaments && ((_vm$player12 = _vm.player) === null || _vm$player12 === void 0 ? void 0 : (_vm$player12$tourname = _vm$player12.tournaments) === null || _vm$player12$tourname === void 0 ? void 0 : _vm$player12$tourname.length) > 0 ? _c('ul', {
    staticClass: "d-flex flex-column align-items-start list-unstyled"
  }, _vm._l((_vm$player13 = _vm.player) === null || _vm$player13 === void 0 ? void 0 : _vm$player13.tournaments, function (tournament, index) {
    var _tournament$disciplin;
    return _c('li', {
      key: index,
      staticClass: "m-1"
    }, [_c('b-link', {
      attrs: {
        "to": {
          name: 'tournament-info',
          params: {
            id: tournament.id,
            game: tournament === null || tournament === void 0 ? void 0 : (_tournament$disciplin = tournament.discipline) === null || _tournament$disciplin === void 0 ? void 0 : _tournament$disciplin.slug
          }
        }
      }
    }, [_c('span', {
      class: "bullet bullet-sm mr-1 bullet-".concat(_vm.tournamentStatusColor(tournament.status_id)),
      attrs: {
        "title": _vm.tournamentStatusTitle(tournament.status_id)
      }
    }), _vm._v(" "), _c('span', {
      attrs: {
        "title": _vm.tournamentStatusTitle(tournament.status_id)
      }
    }, [_vm._v(_vm._s(tournament.name))])])], 1);
  }), 0) : _vm._e()]), !((_vm$player14 = _vm.player) !== null && _vm$player14 !== void 0 && _vm$player14.tournaments) || ((_vm$player15 = _vm.player) === null || _vm$player15 === void 0 ? void 0 : (_vm$player15$tourname = _vm$player15.tournaments) === null || _vm$player15$tourname === void 0 ? void 0 : _vm$player15$tourname.length) === 0 ? _c('p', {
    staticClass: "font-italic p-2"
  }, [_vm._v(" Exciting tournaments are coming soon! Stay tuned for the action. ")]) : _vm._e()], 1), _c('b-tab', {
    attrs: {
      "title": "Achievements"
    }
  }, [((_vm$player16 = _vm.player) === null || _vm$player16 === void 0 ? void 0 : (_vm$player16$achievem = _vm$player16.achievements) === null || _vm$player16$achievem === void 0 ? void 0 : _vm$player16$achievem.length) > 0 ? _c('ul', {
    staticClass: "d-flex m-0 p-0 align-items-center flex-wrap p-2"
  }, _vm._l((_vm$player17 = _vm.player) === null || _vm$player17 === void 0 ? void 0 : _vm$player17.achievements, function (achievement, index) {
    return _c('li', {
      key: index
    }, [_vm._v(" " + _vm._s(achievement.name) + " ")]);
  }), 0) : _vm._e(), !((_vm$player18 = _vm.player) !== null && _vm$player18 !== void 0 && _vm$player18.achievements) || ((_vm$player19 = _vm.player) === null || _vm$player19 === void 0 ? void 0 : (_vm$player19$achievem = _vm$player19.achievements) === null || _vm$player19$achievem === void 0 ? void 0 : _vm$player19$achievem.length) === 0 ? _c('p', {
    staticClass: "font-italic p-2"
  }, [_vm._v(" Get ready to fill up this space with trophies and triumphs! ")]) : _vm._e()]), _c('b-tab', {
    attrs: {
      "title": "History"
    }
  }, [((_vm$player20 = _vm.player) === null || _vm$player20 === void 0 ? void 0 : (_vm$player20$history = _vm$player20.history) === null || _vm$player20$history === void 0 ? void 0 : _vm$player20$history.length) > 0 ? _c('ul', {
    staticClass: "d-flex m-0 p-0 align-items-center flex-wrap p-2"
  }, _vm._l((_vm$player21 = _vm.player) === null || _vm$player21 === void 0 ? void 0 : _vm$player21.history, function (history, index) {
    return _c('li', {
      key: index
    }, [_vm._v(" " + _vm._s(history.name) + " ")]);
  }), 0) : _vm._e(), !((_vm$player22 = _vm.player) !== null && _vm$player22 !== void 0 && _vm$player22.history) ? _c('p', {
    staticClass: "font-italic p-2"
  }, [_vm._v(" No history yet. ")]) : _vm._e()])], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    staticClass: "mb-1"
  }, [_vm.canEdit ? _c('b-row', [_c('b-col', [_c('my-teams-list-card', {
    attrs: {
      "invites": (_vm$me = _vm.me) === null || _vm$me === void 0 ? void 0 : _vm$me.team_invites,
      "teams": (_vm$me2 = _vm.me) === null || _vm$me2 === void 0 ? void 0 : _vm$me2.teams
    },
    on: {
      "add-team-clicked": _vm.handleAddTeamClick
    }
  })], 1)], 1) : _vm._e(), !_vm.canEdit ? _c('b-row', [_c('b-col', [_c('player-teams-list-card', {
    attrs: {
      "teams": (_vm$player23 = _vm.player) === null || _vm$player23 === void 0 ? void 0 : _vm$player23.teams
    }
  })], 1)], 1) : _vm._e(), _vm.showApplyForOrganizer ? _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', [_c('organizer-apply-card')], 1)], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }