<template>
  <b-modal
    id="team-membership-invite-modal"
    :title="title"
    size="md"
    @hidden="handleHide"
  >
    <template #modal-footer="{ cancel }">
      <b-row class="w-100 mr-1 ml-1 justify-content-start">
        <dismissible-message-box
          :show="showErrorNotifications"
          :variant="MessageType.ERROR"
        >
          <feather-icon
            class="mr-50"
            icon="InfoIcon"
          />
          There was an error saving your decision. Please try again or contact our support
        </dismissible-message-box>

        <dismissible-message-box
          v-if="showSuccessNotifications"
          :show="showSuccessNotifications"
          :variant="MessageType.SUCCESS"
        >
          You are now a member of the team
          {{ invitationLocal?.team?.name }}
        </dismissible-message-box>
      </b-row>
      <b-button
        variant="outline-primary"
        @click="cancel"
      >
        Close
      </b-button>
    </template>

    <div class="d-flex flex-column align-items-center">
      <b-avatar
        :src="invitationLocal?.team?.avatar_image"
        :variant="`light-primary`"
        class="mb-2"
        size="104px"
      />
      <h4 class="mb-2">
        {{ invitationLocal?.team?.name }}
      </h4>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mb-2"
        size="sm"
        variant="primary"
        @click="handleViewProfileClick"
      >
        View team profile
      </b-button>
      <div class="d-flex justify-content-between w-50">
        <spinning-button
          class="mt-2"
          variant="success"
          :is-loading="isLoadingAccept"
          @click.native="() => handleAcceptClick(true)"
        >
          Accept
        </spinning-button>
        <spinning-button
          class="mt-2"
          variant="danger"
          :is-loading="isLoadingDecline"
          @click.native="() => handleAcceptClick(false)"
        >
          Decline
        </spinning-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BAvatar, BButton, BModal, BRow, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'
import MessageType from '@/constants/MessageType'
import SpinningButton from '@/components/SpinningButton.vue'

export default {
  components: {
    SpinningButton,
    DismissibleMessageBox,
    BRow,
    BModal,
    BButton,
    BAvatar,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    invitation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      MessageType,
      showErrorNotifications: false,
      showSuccessNotifications: false,
      invitationLocal: this.invitation,
      isLoadingAccept: false,
      isLoadingDecline: false,
    }
  },
  computed: {
    title() {
      return `Team ${this.invitationLocal?.team?.name} has invited you to join!`
    },
  },
  watch: {
    invitation(data) {
      this.invitationLocal = data
    },
  },
  methods: {
    handleViewProfileClick() {
      this.$router.push({
        name: 'team-profile',
        params: { id: this.invitationLocal?.team?.id },
      })
    },

    async handleAcceptClick(accept) {
      this.isLoadingAccept = accept
      this.isLoadingDecline = !accept

      this.showSuccessNotifications = false
      this.showErrorNotifications = false

      const { errors } = await this.$api.team.respondToTeamInvite(
        this.invitationLocal.token,
        accept,
      )

      this.isLoadingAccept = false
      this.isLoadingDecline = false

      if (errors && errors.length > 0) {
        this.showErrorNotifications = true
        return
      }

      this.showSuccessNotifications = true

      setTimeout(() => {
        this.$emit('inviteStatusChanged', { acepted: accept, invitation: this.invitationLocal })

        this.$bvModal.hide('team-membership-invite-modal')
      }, 5000)
    },

    handleHide() {
      this.isLoadingAccept = false
      this.isLoadingDecline = false
      this.showSuccessNotifications = false
      this.showErrorNotifications = false
    },
  },
}
</script>

<style lang="scss">
  .modal-xl {
    margin-left: 17%;
    margin-right: 17%;
  }

  .modal .modal-header .close {
    transform: translate(-4px, 5px);

    &:hover,
    &:focus,
    &:active {
      transform: translate(-7px, 6px);
    }
  }
</style>
