var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    staticClass: "text-center mb-0",
    attrs: {
      "title": "Apply for organizer"
    }
  }, [_c('b-card-text', {
    staticClass: "text-left"
  }, [_vm._v(" You have community and would like to organizer your first event with ease? We got you covered! Head over to our application form and answer few short questions to apply. ")]), _c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.redirectOrganizerApply
    }
  }, [_vm._v(" Apply for organizer ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }