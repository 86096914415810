var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "text-center",
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "p-1"
  }, [_c('h4', [_vm._v("Teams")]), !_vm.teams || _vm.teams.length === 0 ? _c('p', {
    staticClass: "font-italic"
  }, [_vm._v(" Player does not belong to any team. ")]) : _c('vue-perfect-scrollbar', {
    staticClass: "scrollable-container media-list scroll-area",
    staticStyle: {
      "max-height": "200px"
    },
    attrs: {
      "settings": _vm.perfectScrollbarSettings,
      "tagname": "div"
    }
  }, _vm._l(_vm.teams, function (team, index) {
    return _c('b-media', {
      key: index,
      staticClass: "d-flex align-items-center my-teams-list",
      scopedSlots: _vm._u([{
        key: "aside",
        fn: function fn() {
          return [_c('b-avatar', {
            attrs: {
              "alt": team.name,
              "src": team === null || team === void 0 ? void 0 : team.avatar_image,
              "title": team.name,
              "blank-color": "#ccc",
              "rounded": "circle",
              "width": "42"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('h6', {
      staticClass: "media-heading"
    }, [_vm._v(" " + _vm._s(team.name) + " ")]), _c('b-button', {
      attrs: {
        "size": "sm",
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.redirectToTeamProfile(team.id);
        }
      }
    }, [_vm._v(" Profile ")])], 1)]);
  }), 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }