<template>
  <div>
    <b-card class="text-center mb-0">
      <b-card-text class="text-left">
        <!-- Team List -->

        <div class="media-list">
          <h5 v-if="teams?.length === 0">
            Create your team
          </h5>
          <h5 v-else>
            My teams
          </h5>

          <p
            v-if="teams?.length === 0"
            class="font-italic text-justify"
          >
            Before you jump in, let's build your dream team. You can always form multiple squads, but a single, strong team with lots of members can be a real powerhouse. Ready to assemble your crew?
          </p>

          <vue-perfect-scrollbar
            v-else
            :settings="perfectScrollbarSettings"
            class="scroll-area"
            tagname="div"
            style="max-height: 150px"
          >
            <b-media
              v-for="team in teams"
              :key="team.id"
              class="d-flex align-items-center my-teams-list"
            >
              <template #aside>
                <b-avatar
                  :alt="team.name"
                  :src="team?.avatar_image"
                  :title="team.name"
                  blank-color="#ccc"
                  rounded="circle"
                  width="42"
                />
              </template>
              <div class="d-flex justify-content-between align-items-center">
                <h6 class="media-heading">
                  {{ team.name }}
                </h6>

                <b-button
                  size="sm"
                  variant="primary"
                  @click="redirectToTeamProfile(team.id)"
                >
                  Visit
                </b-button>
              </div>
            </b-media>
          </vue-perfect-scrollbar>

          <!-- Add team -->
          <div class="d-flex align-items-end my-teams-list">
            <b-button
              size="sm"
              variant="outline-primary"
              @click="handleAddTeamClick"
            >
              <span
                v-if="teams?.length === 0"
                class="align-middle"
              >
                Create your first team
              </span>
              <span
                v-else
                class="align-middle"
              >Add</span>
            </b-button>
          </div>
        </div>
        <!--/ Team List -->

        <hr>

        <!-- Team Invites -->
        <div class="media-list">
          <h5>Team invites</h5>

          <p
            v-if="!invites || invites.length === 0"
            class="font-italic"
          >
            No pending invites.
          </p>

          <b-media
            v-for="invite in invites"
            v-else
            :key="invite.id"
            class="d-flex align-items-center my-teams-list"
          >
            <template #aside>
              <b-avatar
                :alt="invite.player?.name"
                :src="invite?.player?.avatar_image"
                :text="invite?.player?.name.substring(0,1)"
                blank-color="#ccc"
                rounded="circle"
                width="42"
              />
            </template>
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="media-heading">
                {{ invite?.player?.name }}
              </h6>
              <b-button
                size="sm"
                variant="primary"
                @click="() => handleInvitationRespondClick(invite)"
              >
                Respond
              </b-button>
            </div>
          </b-media>
        </div>
        <!--/ Team Invites -->
      </b-card-text>
    </b-card>

    <team-membership-invite-modal
      :invitation="currentInvitation"
      @inviteStatusChanged="handleInviteStatusUpdated"
    />
  </div>
</template>

<script>
import {
  BAvatar, BButton, BCard, BCardText, BMedia,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import TeamMembershipInviteModal from '@/views/player/TeamMembershipInviteModal.vue'

export default {
  components: {
    TeamMembershipInviteModal,
    BCard,
    BCardText,
    BMedia,
    BButton,
    BAvatar,
    VuePerfectScrollbar,
  },
  props: {
    teams: {
      type: Array,
      default: () => null,
    },
    invites: {
      type: Array,
      default: () => null,
    },
  },
  data() {
    return {
      isGuest: true,
      currentInvitation: null,
      perfectScrollbarSettings: {
        maxScrollbarLength: 80,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    me() {
      return this.$store.getters['user/getMe']
    },
  },
  methods: {
    async handleInviteStatusUpdated() {
      await this.$store.dispatch('user/fetchMe')
    },
    handleAddTeamClick() {
      this.$router.push({ name: 'team-create' })
    },
    redirectToTeamProfile(teamId) {
      this.$router.push({ name: 'team-profile', params: { id: teamId } })
    },
    handleInvitationRespondClick(invitation) {
      this.currentInvitation = invitation

      this.$bvModal.show('team-membership-invite-modal')
    },
  },
}
</script>

<style lang="scss">
  .my-teams-list {
    padding: 0.75rem !important;
  }

  .scroll-area {
    position: relative;
    margin: auto;
  }
</style>
