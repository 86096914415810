var render = function () {
  var _vm$teams, _vm$teams2, _vm$teams3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    staticClass: "text-center mb-0"
  }, [_c('b-card-text', {
    staticClass: "text-left"
  }, [_c('div', {
    staticClass: "media-list"
  }, [((_vm$teams = _vm.teams) === null || _vm$teams === void 0 ? void 0 : _vm$teams.length) === 0 ? _c('h5', [_vm._v(" Create your team ")]) : _c('h5', [_vm._v(" My teams ")]), ((_vm$teams2 = _vm.teams) === null || _vm$teams2 === void 0 ? void 0 : _vm$teams2.length) === 0 ? _c('p', {
    staticClass: "font-italic text-justify"
  }, [_vm._v(" Before you jump in, let's build your dream team. You can always form multiple squads, but a single, strong team with lots of members can be a real powerhouse. Ready to assemble your crew? ")]) : _c('vue-perfect-scrollbar', {
    staticClass: "scroll-area",
    staticStyle: {
      "max-height": "150px"
    },
    attrs: {
      "settings": _vm.perfectScrollbarSettings,
      "tagname": "div"
    }
  }, _vm._l(_vm.teams, function (team) {
    return _c('b-media', {
      key: team.id,
      staticClass: "d-flex align-items-center my-teams-list",
      scopedSlots: _vm._u([{
        key: "aside",
        fn: function fn() {
          return [_c('b-avatar', {
            attrs: {
              "alt": team.name,
              "src": team === null || team === void 0 ? void 0 : team.avatar_image,
              "title": team.name,
              "blank-color": "#ccc",
              "rounded": "circle",
              "width": "42"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('h6', {
      staticClass: "media-heading"
    }, [_vm._v(" " + _vm._s(team.name) + " ")]), _c('b-button', {
      attrs: {
        "size": "sm",
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.redirectToTeamProfile(team.id);
        }
      }
    }, [_vm._v(" Visit ")])], 1)]);
  }), 1), _c('div', {
    staticClass: "d-flex align-items-end my-teams-list"
  }, [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.handleAddTeamClick
    }
  }, [((_vm$teams3 = _vm.teams) === null || _vm$teams3 === void 0 ? void 0 : _vm$teams3.length) === 0 ? _c('span', {
    staticClass: "align-middle"
  }, [_vm._v(" Create your first team ")]) : _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Add")])])], 1)], 1), _c('hr'), _c('div', {
    staticClass: "media-list"
  }, [_c('h5', [_vm._v("Team invites")]), !_vm.invites || _vm.invites.length === 0 ? _c('p', {
    staticClass: "font-italic"
  }, [_vm._v(" No pending invites. ")]) : _vm._l(_vm.invites, function (invite) {
    var _invite$player4;
    return _c('b-media', {
      key: invite.id,
      staticClass: "d-flex align-items-center my-teams-list",
      scopedSlots: _vm._u([{
        key: "aside",
        fn: function fn() {
          var _invite$player, _invite$player2, _invite$player3;
          return [_c('b-avatar', {
            attrs: {
              "alt": (_invite$player = invite.player) === null || _invite$player === void 0 ? void 0 : _invite$player.name,
              "src": invite === null || invite === void 0 ? void 0 : (_invite$player2 = invite.player) === null || _invite$player2 === void 0 ? void 0 : _invite$player2.avatar_image,
              "text": invite === null || invite === void 0 ? void 0 : (_invite$player3 = invite.player) === null || _invite$player3 === void 0 ? void 0 : _invite$player3.name.substring(0, 1),
              "blank-color": "#ccc",
              "rounded": "circle",
              "width": "42"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('h6', {
      staticClass: "media-heading"
    }, [_vm._v(" " + _vm._s(invite === null || invite === void 0 ? void 0 : (_invite$player4 = invite.player) === null || _invite$player4 === void 0 ? void 0 : _invite$player4.name) + " ")]), _c('b-button', {
      attrs: {
        "size": "sm",
        "variant": "primary"
      },
      on: {
        "click": function click() {
          return _vm.handleInvitationRespondClick(invite);
        }
      }
    }, [_vm._v(" Respond ")])], 1)]);
  })], 2)])], 1), _c('team-membership-invite-modal', {
    attrs: {
      "invitation": _vm.currentInvitation
    },
    on: {
      "inviteStatusChanged": _vm.handleInviteStatusUpdated
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }