<template>
  <div>
    <b-row v-if="isLoading">
      <b-col class="text-center">
        <b-spinner label="Loading..." />
      </b-col>
    </b-row>

    <!-- Player info -->
    <b-row v-else>
      <b-col
        lg="9"
        md="12"
      >
        <b-row>
          <b-col>

            <!-- Player card -->
            <b-card
              no-body
              class="p-2"
            >
              <b-row v-if="isLoading">
                <b-col class="text-center">
                  <b-spinner label="Loading..." />
                </b-col>
              </b-row>

              <b-row v-if="player">
                <!-- Profile info -->
                <b-col
                  lg="6"
                  md="12"
                >
                  <!-- Player name -->
                  <b-row>
                    <!-- Player Avatar -->
                    <b-col
                      md="3"
                      lg="4"
                      class="mb-lg-0 mb-1"
                    >
                      <b-avatar
                        :src="player?.avatar_image"
                        :text="player?.name?.substring(0, 2)"
                        :variant="`light-primary`"
                        rounded
                        size="120px"
                      />
                    </b-col>

                    <b-col
                      md="9"
                      lg="8"
                    >
                      <b-row>
                        <b-col class="d-flex align-items-start">
                          <h4 class="mb-0 mr-1">
                            {{ player?.name }}
                          </h4>
                          <span
                            class="ml-1"
                          >
                            <b-button
                              v-if="canEdit"
                              class="mb-1 font-small-1"
                              size="sm"
                              variant="primary"
                              @click="handleEditClick"
                            >
                              Edit
                            </b-button>
                          </span>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <div
                            v-if="canEdit"
                            class="mb-0"
                          >
                            {{ player?.email }}
                          </div>
                        </b-col>
                      </b-row>

                      <!-- social links -->
                      <b-row>
                        <b-col class="mb-1 mr-1">
                          <social-links-list :links="player?.links" />
                        </b-col>
                      </b-row>

                    </b-col>
                  </b-row>
                  <!-- /Player name -->

                  <!-- Wallet -->
                  <b-row class="flex-nowrap mt-1">
                    <b-col
                      md="3"
                      lg="4"
                    >
                      <b-badge
                        class="p-1 font-medium-2 w-100"
                        style="max-width: 120px"
                        variant="light-success"
                      >
                        {{ player?.balance || 0 }} €
                      </b-badge>
                    </b-col>

                    <b-col
                      md="9"
                    >
                      <b-row class="flex-column">
                        <b-col
                          lg="6"
                          md="3"
                        >
                          <b-button
                            v-if="canAddFunds"
                            class="mb-1 font-small-1"
                            disabled="disabled"
                            size="sm"
                            variant="primary"
                          >
                            Add funds
                          </b-button>
                        </b-col>

                        <b-col
                          lg="6"
                          md="3"
                        >
                          <b-button
                            v-if="canWithdrawFunds"
                            class="mb-1 font-small-1"
                            disabled="disabled"
                            size="sm"
                            variant="primary"
                          >
                            Withdraw funds
                          </b-button>
                        </b-col>
                      </b-row>

                    </b-col>
                  </b-row>
                  <!-- /Wallet -->
                </b-col>
                <!-- /Profile info -->

                <!-- Latest achievements -->
                <b-col
                  lg="6"
                  md="12"
                  class="mt-1 mt-lg-0"
                >
                  <h6>Latest achievements</h6>

                  <div>
                    <p
                      v-if="!player?.achievements || player?.achievements?.length === 0"
                      class="font-italic"
                    >
                      Get ready to fill up this space with trophies and triumphs!
                    </p>
                    <ul
                      v-if="player?.achievements?.length > 0"
                      class="profile-achievements d-flex m-0 p-0 align-items-center flex-wrap"
                    >
                      <li
                        v-for="(achievement, index) in player?.achievements"
                        :key="index"
                      >
                        {{ achievement.name }}
                      </li>
                    </ul>
                  </div>
                </b-col>
                <!-- /Latest achievements -->
              </b-row>

            </b-card>
            <!-- /Team card -->
          </b-col>
        </b-row>
        <!-- /Player info -->

        <b-row>
          <b-col>
            <!-- Player tournaments & achievements -->
            <b-card
              class="p-1"
              no-body
            >
              <b-tabs pills>
                <b-tab
                  active
                  title="Tournaments"
                >
                  <vue-perfect-scrollbar
                    class="scroll-area"
                    style="max-height: 200px"
                  >
                    <ul
                      v-if="player?.tournaments && player?.tournaments?.length > 0"
                      class="d-flex flex-column align-items-start list-unstyled"
                    >
                      <li
                        v-for="(tournament, index) in player?.tournaments"
                        :key="index"
                        class="m-1"
                      >
                        <b-link :to="{name: 'tournament-info', params: {id: tournament.id, game: tournament?.discipline?.slug}}">
                          <span
                            :title="tournamentStatusTitle(tournament.status_id)"
                            :class="`bullet bullet-sm mr-1 bullet-${tournamentStatusColor(tournament.status_id)}`"
                          /> <span :title="tournamentStatusTitle(tournament.status_id)">{{ tournament.name }}</span>
                        </b-link>

                      </li>
                    </ul>
                  </vue-perfect-scrollbar>
                  <p
                    v-if="!player?.tournaments || player?.tournaments?.length === 0"
                    class="font-italic p-2"
                  >
                    Exciting tournaments are coming soon! Stay tuned for the action.
                  </p>
                </b-tab>
                <b-tab title="Achievements">
                  <ul
                    v-if="player?.achievements?.length > 0"
                    class="d-flex m-0 p-0 align-items-center flex-wrap p-2"
                  >
                    <li
                      v-for="(achievement, index) in player?.achievements"
                      :key="index"
                    >
                      {{ achievement.name }}
                    </li>
                  </ul>
                  <p
                    v-if="!player?.achievements || player?.achievements?.length === 0"
                    class="font-italic p-2"
                  >
                    Get ready to fill up this space with trophies and triumphs!
                  </p>
                </b-tab>
                <b-tab title="History">
                  <ul
                    v-if="player?.history?.length > 0"
                    class="d-flex m-0 p-0 align-items-center flex-wrap p-2"
                  >
                    <li
                      v-for="(history, index) in player?.history"
                      :key="index"
                    >
                      {{ history.name }}
                    </li>
                  </ul>
                  <p
                    v-if="!player?.history"
                    class="font-italic p-2"
                  >
                    No history yet.
                  </p>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-col>
        </b-row>

      </b-col>

      <!-- Right side cards -->
      <b-col class="mb-1">
        <b-row v-if="canEdit">
          <b-col>
            <my-teams-list-card
              :invites="me?.team_invites"
              :teams="me?.teams"
              @add-team-clicked="handleAddTeamClick"
            />
          </b-col>
        </b-row>

        <b-row
          v-if="!canEdit"
        >
          <b-col>
            <player-teams-list-card :teams="player?.teams" />
          </b-col>
        </b-row>

        <b-row
          v-if="showApplyForOrganizer"
          class="mt-1"
        >
          <b-col>
            <organizer-apply-card />
          </b-col>
        </b-row>
      </b-col>
      <!-- /Right side cards -->

    </b-row>
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BButton, BCard, BCol, BLink, BRow, BSpinner, BTab, BTabs,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import SocialLinksList from '@/views/common/SocialLinksList.vue'
import MyTeamsListCard from '@/views/player/MyTeamsListCard.vue'
import PlayerTeamsListCard from '@/views/player/PlayerTeamsListCard.vue'
import OrganizerApplyCard from '@/views/player/OrganizerApplyCard.vue'
import { canEditPlayer, showMyTeamsCard } from '@/views/player/playerUtils'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { tournamentStatusColor, tournamentStatusTitle } from '@/views/tournament/tournament-utils'
import { defineAbilitiesFor } from '@/libs/acl/ability'
import { Ability } from '@casl/ability'
import { breadcrumbManager } from '@core/mixins/bredcrumb-manager'

export default {
  components: {
    BLink,
    OrganizerApplyCard,
    PlayerTeamsListCard,
    MyTeamsListCard,
    BSpinner,
    SocialLinksList,
    BAvatar,
    BBadge,
    BCard,
    BButton,
    BTabs,
    BTab,
    BCol,
    BRow,
    VuePerfectScrollbar,

  },
  mixins: [
    breadcrumbManager,
  ],
  data() {
    return {
      isLoading: false,
      player: {},
      showApplyForOrganizer: true,
      ability: new Ability(),
    }
  },
  computed: {
    canAddFunds() {
      return this.ability.can('addFunds', 'Player')
    },
    canWithdrawFunds() {
      return this.ability.can('withdrawFunds', 'Player')
    },
    canEdit() {
      return canEditPlayer(this.player)
    },
    me() {
      return this.$store.getters['user/getMe']
    },
  },
  async mounted() {
    const { id } = this.$route.params

    if (!id) {
      await this.$router.push('error-404')
    }

    this.isLoading = true

    const { data } = await this.$api.player.fetchUser(id)

    this.isLoading = false

    if (!data) {
      await this.$router.push('error-404')
    }

    this.player = data

    const abilities = await defineAbilitiesFor({ player: this.player })

    this.ability.update(abilities)

    this.replaceBreadcrumb({
      find: 'playerName',
      replace: { text: this.player?.name, loading: false },
    })
  },
  methods: {
    tournamentStatusColor,
    tournamentStatusTitle,
    showMyTeamsCard,
    handleEditClick() {
      this.$router.push({
        name: 'account-settings',
      })
    },
    handleAddTeamClick() {
    },
  },
}
</script>

<style lang="scss">
.profile-achievements {
  list-style: none;

  li {
    margin: 0.5rem;
  }
}
</style>
